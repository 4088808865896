import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { IsNullOrEmpty } from "../../../services/CustomService";
import {
    useLoaderData,
    Form,
    useNavigate,
    useSubmit
} from "react-router-dom";
import { getInvestment } from "../../../data/money/investments";
export async function loader({ params }) {
    const investment = await getInvestment(params.investmentId);
    if (!investment) {
        throw new Response("", {
            status: 404,
            statusText: "Not Found",
        });
    }
    return { investment };
}
export default function Investment() {
    const { investment } = useLoaderData();
    const navigate = useNavigate();

    const submit = useSubmit();
    const accept = () => {
        submit(null, { method: "post", action: "delete" })
    };

    const reject = () => {
    };

    const deleteInvest = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir supprimer cette source ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept,
            reject
        });
    };

    const items = [
        {
            label: investment.sourceName,
            template: () => <a onClick={() => { navigate(-1); }}>{investment.sourceName}</a>
        },
        {
            label: investment.name,
        }
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    let purchaseDate = new Date(investment.purchaseDate)
    return (
        <div>
            <BreadCrumb model={items} home={home} />
            <div id="thumbnail">


                {(!IsNullOrEmpty(investment.base64Picture)) ? (
                    <img height="192" key={investment.publicId} alt={investment.name} src={investment.base64Picture || null} />

                ) : (
                    <div className="no-picture-money no-picture-large"></div>
                )}

                <div>
                <h1>
                        {investment.name ? (
                        <>
                                {investment.name}
                        </>
                    ) : (
                        <i>Inconnu</i>
                    )}{" "}
                </h1>

                    {investment.link && (
                <p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                                href={investment.link}
                    >
                                {investment.link}
                    </a>
                </p>
                )}
                    {investment.description && (
                        <p>
                            {investment.description}
                        </p>
                    )}

                <div>
                    <Form action="edit">
                            <Button type="submit" size="small">Modifier l'investissement</Button>
                    </Form>
                        <ConfirmPopup />
                        <Button onClick={deleteInvest} icon="pi pi-trash" label="" size="small" className="p-button-danger"></Button>
                    </div>


                </div>
                
            </div>
            <div id="mainContent">

                <div class="totalAmount"><b>Total d'investissement : </b> {Number(investment.nbUnits * investment.value).toFixed(0)}&euro; ({investment.nbUnits}*{investment.value}&euro; )</div>
                <div class="totalAmount"><b>Achat le </b>{purchaseDate.toLocaleDateString("fr")}</div>
                <div class="totalAmount"><b>Valeur actuelle :</b>  {Number(investment.nbUnits * investment.currentValue).toFixed(0)}&euro;</div>

            </div>
    </div>
    );
}
