import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { getMapping } from "../routes/ModulesMapping";
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import React, { useRef } from "react";
import {
    Form
} from "react-router-dom";
import { useFormik } from 'formik';
import { login, getCurrentUser } from "../data/auth.service";

export default function Login() {
    const toast = useRef(null);

    // Formik ---------------------

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''

        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = 'email is required.';
            }
            if (!data.password) {
                errors.password = 'password is required.';
            }
            return errors;
        },
        onSubmit: async (data) => {
            var ret = await login(data);
            if (ret.status === 401) {
                toast.current.show({ severity: 'warn', summary: 'Oops', detail: "Identifiant ou mot de passe invalide !", life: 6000 });
            } else {
                let user = getCurrentUser();
                if (user && user.roles) {
                    var loc = getMapping(user.roles[0]);
                    return window.location.replace(loc); 
                }
                return window.location.replace('/login'); 
            }
            
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    //----------------------------------------


    return (
        <div id="login">
            <div className="card flex justify-content-center">
                <Toast ref={toast} />
            </div>
 
            <Form method="post" id="thumbnail-form" onSubmit={formik.handleSubmit}>
                <Panel header="Connexion">
                <div className="contentForm">
                    <span className="p-float-label">
                            <InputText id="email" name="email" value={formik.values.email} data-cy="emailInput"
                            onChange={(e) => {
                                formik.setFieldValue('email', e.target.value);
                            }}
                            className={classNames({ 'p-invalid': isFormFieldInvalid('email') })}
                        />
                        <label htmlFor="email">Email</label>
                    </span>
                </div>

                <div className="contentForm">
                    <span className="p-float-label">
                   
                            <Password id="password" name="password" value={formik.values.password}
                                data-cy="passwordInput"
                            feedback={false}
                            onChange={(e) => {
                                formik.setFieldValue('password', e.target.value);
                            }}
                            className={classNames({ 'p-invalid': isFormFieldInvalid('password') })}
                        />
                        <label htmlFor="password">Mot de passe</label>
                    </span>
                    </div>
                    <div className="contentForm"></div >
                </Panel>
                <p>
                    <Button type="submit" size="small" data-cy="connexionBtn">Se connecter</Button>
                    <a href="/inscription" rel="noopener noreferrer" >
                        <i className="pi pi-user-plus" ></i> S'inscrire
                    </a>
                </p>
            </Form>
        </div>
    );
}