import React from "react";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'react-tooltip'
import { IsNullOrEmpty } from "../../../services/CustomService";
import {
    Form,
    redirect,
    useLoaderData,
    useNavigate,
    useSubmit
} from "react-router-dom";
import { addInvestment } from "../../../data/money/investments";
import { getAvalaibleAssets } from "../../../data/money/assets";

export async function action({ request, params }) {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);
    await addInvestment(params.sourceId, data);

    return redirect(`/sources/${params.sourceId}`);
}
export async function loader({ params }) {
    const avalaibleassets = await getAvalaibleAssets(params.sourceId);
    if (!avalaibleassets) {
        throw new Response("", {
            status: 404,
            statusText: "Not Found",
        });
    }
    return { avalaibleassets };
}


export default function AddInvestment() {
    const navigate = useNavigate();
    const { avalaibleassets } = useLoaderData();
    const submit = useSubmit();


    // Gestion des avalaibleassets ---------------------

    let formatteAssets = [];
    for (let i = 0; i < avalaibleassets.length; i++) {
        formatteAssets[i] = { ...avalaibleassets[i], name: avalaibleassets[i].name + " : " + avalaibleassets[i].publicId, value: avalaibleassets[i].publicId };
    }

    const selectedItemTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    {(!IsNullOrEmpty(option.base64Picture)) ? (
                        <img className="smallPicture choiceService" key={option.id} alt={option.name} src={option.base64Picture || null} />

                    ) : (
                        <div className="no-picture-service no-picture-small choiceService"></div>
                    )}
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;    
    };

    const itemOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {(!IsNullOrEmpty(option.base64Picture)) ? (
                    <img className="smallPicture choiceService" key={option.id} alt={option.name} src={option.base64Picture || null} />

                ) : (
                    <div className="no-picture-service no-picture-small choiceService"></div>
                )}
                <div>{option.name}</div>
            </div>
        );
    };
    // ----------------------------------------

    // Formik ---------------------

    const formik = useFormik({
        initialValues: {
            assetId: '',
            nbUnits: '',
            value: '',
            purchaseDate:''

        },
        validate: (data) => {
            let errors = {};

            if (!data.assetId) {
                errors.assetId = 'asset is required.';
            }
            if (!data.nbUnits) {
                errors.nbUnits = 'nbUnits is required.';
            }
            if (!data.value) {
                errors.value = 'value is required.';
            }
            if (!data.purchaseDate) {
                errors.purchaseDate = 'purchaseDate is required.';
            }
            return errors;
        },
        onSubmit: async (data) => {
            submit(data, { method: "post" })
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    //----------------------------------------



    return (
        <div>
            {avalaibleassets.length > 0 && (
                <Form method="post" id="thumbnail-form" onSubmit={formik.handleSubmit}>
                    <Panel header="Ajout d'un investissement">
                        <div className="contentForm">
               
                            <Dropdown id="assetId" name="assetId" value={formik.values.assetId}
                                onChange={(e) => {
                                    formik.setFieldValue('assetId', e.target.value);
                                }}
                                className={"w-full md:w-14rem" && classNames({ 'p-invalid': isFormFieldInvalid('assetId') })}
                                options={formatteAssets} optionLabel="name" placeholder="S&eacute;lectionnez un actif"
                                filter valueTemplate={selectedItemTemplate} itemTemplate={itemOptionTemplate} />
                            <div data-tooltip-id="AddAssetToolTip"
                                data-tooltip-html="<center>Vous ne trouvez pas votre actif ?<br/>Créez-en un !</center>"
                                //data-tooltip-place="top"
                                data-tooltip-delay-hide={1000}
                                className="AddAssetToolTip">
                                <i className="pi pi-question-circle" style={{ fontSize: '2rem' }}></i>
                            </div>
                            <Tooltip id="AddAssetToolTip" anchorSelect=".AddAssetToolTip" />
                        </div>
                        <div className="contentForm">

                            <span className="p-float-label">
                                <InputText id="nbUnits" name="nbUnits" value={formik.values.nbUnits}
                                    keyfilter="num"
                                    onChange={(e) => {
                                        formik.setFieldValue('nbUnits', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('nbUnits') })}
                                />
                                <label htmlFor="nbUnits">Nombre d'unité</label>

                            </span>
                            <span className="p-float-label">
                                <InputText id="value" name="value" value={formik.values.value}
                                    keyfilter="num" 
                                    onChange={(e) => {
                                        formik.setFieldValue('value', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('value') })}
                                />

                                <label htmlFor="value">Prix d'achat en &euro;</label>
                            </span>

                            <span className="p-float-label">
                                <Calendar id="purchaseDate" name="purchaseDate" value={formik.values.purchaseDate}
                                    dateFormat="dd/mm/yy"
                                    onChange={(e) => {
                                        formik.setFieldValue('purchaseDate', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('purchaseDate') })}
                                />
                                <label htmlFor="purchaseDate">Date d'achat</label>
                            </span>

                        </div>
                        <div className="contentForm"></div >
                    </Panel>
                <p>
                        <Button type="submit" size="small">Enregistrer</Button>
                        <Button severity="secondary" type="button"
                            size="small"
                        onClick={() => {
                            navigate(-1);
                            }}>Annuler</Button>
                    </p>
             
                </Form>
            )}
            {avalaibleassets.length === 0 && (
                <div id="error-page">
                    <h1>Oops!</h1>
                    <p>Veuillez d'abord cr&eacute;er un actif avant de pouvoir en associer &agrave; la source !</p>
   
                </div>
            )
            }
        </div>
    );
}