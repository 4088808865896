import { getToken, redirectToLogin } from "../data/auth.service";

export async function get(url) {
    const response = await fetch('/api/' + url,
        {
            headers: { Authorization: 'Bearer ' + getToken() }
        });

    if (response.status === 401) {
        redirectToLogin();
    } if (response.status === 402) {
        window.location.replace('/abonnement');
    } else if (response.status === 400) {
        return null;
    } else if (response.status === 404) {
        return null;
    }

    const data = await response.json();

    return data;
}

export async function getPublic(url) {
    const response = await fetch('/api/' + url);
    if (response.status === 404) {
        return null;
    }
    const data = await response.json();

    return data;
}
export async function post(data, url) {

    const response = await fetch('/api/' + url, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        body: JSON.stringify(data)
    });

    if (response.status === 401) {
        redirectToLogin();
    }
    else if (response.status === 403) {
        return response;
    }
    else if (response.status === 500) {
        return response;
    }

    let resp = await response.json();
    return resp;
}
export async function postPublic(data, url) {

    const response = await fetch('/api/' + url, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    return await response.json();
}
export async function putPublic(data, url) {

    const response = await fetch('/api/' + url, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    return await response.json();
}

export async function put(data, url) {
 
    const response = await fetch('/api/' + url, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        body: JSON.stringify(data)
    });

    if (response.status === 401) {
        redirectToLogin();
    }

    return await response.json();
}

export async function del(url) {
    const response = await fetch('/api/' + url, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
    });

    if (response.status === 401) {
        redirectToLogin();
    }

    const data = await response.json();
    return data;
}