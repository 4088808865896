import React from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import 'moment/locale/fr';
import {
    Outlet,
    useLoaderData,
    Form,
    useFetcher,
    useSubmit,
    NavLink
} from "react-router-dom";
import { getSource, updateFavorite } from "../../../data/money/sources";
export async function action({ request, params }) {
    let formData = await request.formData();
    return updateFavorite(params.sourceId, {
        favorite: formData.get("favorite") === "true",
    });
}
export async function loader({ params }) {
    const source = await getSource(params.sourceId);
    if (!source) {
        throw new Response("", {
            status: 404,
            statusText: "Not Found",
        });
    }
    return { source };
}
export default function Source() {
    const { source } = useLoaderData();

    const items = [
        {
            label: source.name,
            template: () => <span>{source.name}</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    const submit = useSubmit();
    const accept = () => {
        submit(null, { method: "post", action: "delete" })
    };

    const reject = () => {
    };

    const deleteSource = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir supprimer cette source ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept,
            reject
        });
    };

    const linkBodyTemplate = (inv) => {
        return <NavLink to={`investments/${inv.publicId}`} >Détails</NavLink>
    };

    return (
        <div>
            <BreadCrumb model={items} home={home} />
        <div id="thumbnail">
            <div>
                <img height="192" key={source.publicId} alt={source.name} src={source.base64Picture || null} />
            </div>

            <div>
                <h1>
                    {source.name ? (
                        <>
                            {source.name}
                        </>
                    ) : (
                        <i>Inconnu</i>
                    )}{" "}
                    <Favorite source={source} />
                </h1>

                {source.link && (
                    <p>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={source.link}
                        >
                            {source.link}
                        </a>
                    </p>
                )}


                <div>
                        <Form action="edit">
                            <Button type="submit" size="small">Modifier</Button>
                    </Form>
                    <Form action="assets/create">
                            <Button type="submit" size="small">Créer un actif</Button>
                    </Form>
                        <ConfirmPopup />
                        <Button onClick={deleteSource} icon="pi pi-trash" label="" size="small" className="p-button-danger"></Button>
                </div>

            </div>

            </div>

            <div id="mainSubContent" >
                
            </div>
            <div id="mainContent">
                <h2 className="titleWithIcon">
                Investissements  

                <Form action="investments/add">
                        <Button type="submit" size="small" icon="pi  pi-plus"></Button>
                    </Form>

                </h2>
                <Outlet />
                {source.investments.length >  0 && (
                <div>
                   
                        <div className="card">
                            <DataTable value={source.investments}>
                                <Column sortable field="nbUnits" header="Quantité"></Column>
                                <Column sortable  field="name" header="Nom"></Column>
                                <Column sortable field="purchaseDate" header="Date d'achat"></Column>
                                <Column header="" body={linkBodyTemplate}></Column>
                            </DataTable>
                        </div>
                        
                        <div class="totalAmount"><b>Total d'investissement:</b> {Number(source.totalInvestment).toFixed(0)}&euro;</div>
                        <div class="totalAmount"><b>Valeur &agrave; ce jour :</b> {Number(source.totalValueForTheDay).toFixed(0)}&euro;</div>
                </div>
                )}
                
   
                
            </div>

    </div>
    );
}

function Favorite({ source }) {
    const fetcher = useFetcher();
    // yes, this is a `let` for later
    let favorite = source.isFavorite;
    if (fetcher.formData) {
        favorite = fetcher.formData.get("favorite") === "true";
    }
    return (
        <fetcher.Form method="post">
            <button
                name="favorite"
                value={favorite ? "false" : "true"}
                aria-label={
                    favorite
                        ? "Remove from favorites"
                        : "Add to favorites"
                }
            >
                {favorite ? "★" : "☆"}
            </button>
        </fetcher.Form>
    );
}