//https://www.bezkoder.com/react-login-example-jwt-hooks/


export async function switchAccount(childId) {
    
    let model = {
        ChildId: childId
    };

    const response = await fetch('/api/authenticate/switch', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + getToken(),        },
       
        body: JSON.stringify(model)
    });
    const data = await response.json();
    if (data) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(data))
    }
    return data;
}
export async function redirectToLogin() {
    localStorage.removeItem("subscription");
    localStorage.removeItem("user");
    window.location.replace('/login');
}
export async function login(params) {
    let source = {
        Email: params.email,
        Password: params.password
    };

    const response = await fetch('/api/authenticate/login', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(source)
    });
    const data = await response.json();
    if (data) {
        localStorage.setItem("user", JSON.stringify(data.user))
    }
    return data;
}

export async function logout() {

    const response = await fetch('/api/authenticate/logout', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });

    localStorage.removeItem("user");
}
export function getCurrentUser() {
    
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
        return user;
    }
    window.location.replace('/login');
}

export function getToken() {

    const user = getCurrentUser();
    let token = '';
    if (user) {
        token = user.token;
    }
    return token;
}