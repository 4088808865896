export default function MoneyIndex() {
    return (
        <p id="zero-state">
            Bienvenue dans la gestion centralis&eacute;e de vos finances.
            <br />
            <a href="/">
                De votre vivant, et apr&egrave;s...
            </a>
        </p>
    );
}