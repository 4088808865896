import { redirect } from "react-router-dom";
import { switchAccount, getCurrentUser } from "../data/auth.service";
import { getMapping } from "../routes/ModulesMapping";

export async function loader({ params }) {

    await switchAccount(params.childId);

    var host = window.location.host
    var subdomain = host.split('.')[0]


    let user = getCurrentUser();
    if ((user && user.roles) || subdomain === "location") {
        var loc = getMapping(user.roles[0]);
        return redirect(loc);
    }
    return redirect(`/login`);
    
}