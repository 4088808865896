import React from 'react';
import { Menubar } from 'primereact/menubar';

export function MenuBar() {
    let user = JSON.parse(localStorage.getItem("user"));

    var accountItems = [
        {
        label: 'D\u00e9connexion',
        icon: 'pi pi-sign-out',
        url: '/logout'
        },
        {
            label: 'Mon compte',
            icon: 'pi pi-user-edit',
            url: '/moncompte'
        }]
    if (user.childs.length > 0) {
        var sub = []
        user.childs.map((child) => {
            sub.push({
                label: child.name,
                icon: 'pi pi-users',
                url: '/login/switch/' + child.publicId
            })
        });
        accountItems.push({
            label: 'Comptes li\u00e9s',
            icon: 'pi pi-sync',
            items: sub
        })
    }
    const items = [

        {
            label: 'Source d\'investissement',
            icon: 'pi pi-plus-circle',
            url: '/sources/add'
        },
     
        {
            icon: 'pi pi-user',
            label: user ? user.name : '',
            items: accountItems
        }
       
    ];

    return (
        <div className="card">
            <Menubar model={items} />
        </div>
    )
}