import { get, post, put, del } from "../../data/api";

export async function getSources() {
    return await get('source')
}
export async function getSource(id) {
    return await get('source/' + id)
}
export async function getSourceTypes() {
    return await get('source/types')
}
export async function updateFavorite(id, updates) {
    let fav = {
        IsFavorite: updates.favorite
    };

    return await put(fav, 'source/' + id)
}
export async function addSource(params) {
    let source = {
        Name: params.name,
        Link: params.link,
        Base64Picture: params.picture,
        CustomUrl: params.customurl,
        AssetType: params.type
    };
    return await post(source, 'source')

}
export async function updateSource(params, id) {
    let source = {
        Name: params.name,
        Link: params.link,
        Base64Picture: params.picture,
        CustomUrl: params.customurl,
        AssetType: params.type
    };

    return await put(source, 'source/' + id)
}
export async function deleteSource(id) {
    return await del('source/' + id)
}