import { get, post } from "../../data/api";

export async function getAssets(query) {
    return await get('asset')
}

export async function getAvalaibleAssets(id) {
    return await get('source/avalaibleassets/' + id)
}

export async function createAsset(parentId, params) {
    let asset = {
        Name: params.name,
        Link: params.link,
        Description: params.description,
        PublicSourceId: parentId,
        Base64Picture: params.picture,
        Symbol: params.symbol,
        ISIN: params.isin,
    };

    return await post(asset, 'asset')
}