import React, { useRef, useState } from "react";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import {
    Form,
    redirect,
    useSubmit
} from "react-router-dom";
import { useFormik } from 'formik';
import { register } from "../data/accounts";
export async function action({ request, params }) {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    await register(data);

    return redirect(`/login`);
}

export default function Register() {
    const [value, setValue] = useState('');
    const header = <div className="font-bold mb-3">Choisir un mot de passe</div>;
    const footer = (
        <>
            <Divider />
            <ul className="pl-2 ml-2 mt-0 line-height-3" data-cy="infoPwd">
                <li>Au moins une minuscule</li>
                <li>Au moins une majuscule</li>
                <li>Au moins un chiffre</li>
                <li>Au moins un caractère non-alphanumérique</li>
                <li>Minimum 6 caractères</li>
            </ul>
        </>
    );

    // Formik ---------------------
    const submit = useSubmit();
    const formik = useFormik({
        initialValues: {
            registerFname: '',
            registerLname: '',
            registerEmail: '',
            registerPwd: '',
            confirmPwd: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.registerFname) {
                errors.registerFname = 'Prénom est obligatoire.';
            }
            if (!data.registerLname) {
                errors.registerLname = 'Nom est obligatoire.';
            }
            if (!data.registerEmail) {
                errors.registerEmail = 'email is required.';
            }
            if (!data.registerPwd) {
                errors.registerPwd = 'password is required.';
            }
            if (!data.confirmPwd) {
                errors.confirmPwd = 'La confirmation du mot de passe est obligatoire';
            }
            if (data.registerPwd !== data.confirmPwd) {
                errors.confirmPwd = 'Les mots de passe ne correspondent pas';
            }

            return errors;
        },
        onSubmit: async (data) => {
            submit(data, { method: "post" })
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    //----------------------------------------


    return (
        <div id="login">
 
            <Form method="post" id="thumbnail-form" onSubmit={formik.handleSubmit}>
                <Panel header="Inscription">
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="registerFname" name="registerFname" value={formik.values.registerFname} data-cy="fnameInput"
                                onChange={(e) => {
                                    formik.setFieldValue('registerFname', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('registerFname') })}
                            />
                            <label htmlFor="registerFname">Pr&eacute;nom</label>
                        </span>
                    </div>
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="registerLname" name="registerLname" value={formik.values.registerLname} data-cy="lnameInput"
                                onChange={(e) => {
                                    formik.setFieldValue('registerLname', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('registerLname') })}
                            />
                            <label htmlFor="registerLname">Nom</label>
                        </span>
                    </div>
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="registerEmail" name="registerEmail" value={formik.values.registerEmail} data-cy="emailInput"
                                onChange={(e) => {
                                    formik.setFieldValue('registerEmail', e.target.value);
                                }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('registerEmail') })}
                            />
                                <label htmlFor="registerEmail">Email</label>
                        </span>
                    </div>

                    <div className="contentForm">
                        <span className="p-float-label">
                   
                            <Password id="registerPwd" name="registerPwd" value={formik.values.registerPwd}  header={header} footer={footer}
                                promptLabel=" " weakLabel="Trop simple" mediumLabel="Moyennement complexe" strongLabel="Très complexe !" data-cy="pwdInput"
                              
                                onChange={(e) => {
                                    formik.setFieldValue('registerPwd', e.target.value);
                                    setValue(e.target.value)
                                }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('registerPwd') })}
                            />
                                <label htmlFor="registerPwd">Mot de passe</label>
                        </span>
                    </div>

                <div className="contentForm">
                        <span className="p-float-label">

                            <Password id="confirmPwd" name="confirmPwd" value={formik.values.confirmPwd}
                                feedback={false} data-cy="pwdconfirmInput"
                                onChange={(e) => {
                                    formik.setFieldValue('confirmPwd', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('confirmPwd') })}
                            />
                            <label htmlFor="confirmPwd">Confirmez le mot de passe</label>
                        </span>
                    </div>
                    <div className="contentForm"></div >
                </Panel>
                <p>
                    <Button type="submit" size="small" data-cy="registerBtn">S'inscrire</Button>
                    <a href="/login" rel="noopener noreferrer" >
                        <i className="pi pi-sign-in" ></i> Se connecter
                    </a>
                </p>
            </Form>
        </div>
    );
}