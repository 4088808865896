import { get, post, put, del } from "../../data/api";
export async function getInvestment(id) {
    return await get('investment/' + id)
}
export async function addInvestment(parentId, params) {
    let bd = new Date(params.purchaseDate);
    let invest = {
        AssetId: params.assetId,
        SourceId: parentId,
        NbUnits: params.nbUnits,
        Value: params.value,
        PurchaseDate: bd
    };
    return await post(invest, 'investment')
}
export async function updateInvestment(params, investmentId) {
    let bd = new Date(params.purchaseDate);
    let inv = {
        NbUnits: params.nbUnits,
        Value: params.value,
        PurchaseDate: bd
    };

    return await put(inv, 'investment/' + investmentId)
}
export async function deleteInvestment(investmentId) {
    return await del('investment/' + investmentId)
}