import React from "react";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { BreadCrumb } from 'primereact/breadcrumb';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from 'primereact/panel';

import {
    Form,
    redirect,
    useLoaderData,
    useNavigate,
    useSubmit
} from "react-router-dom";
import { createAsset } from "../../../data/money/assets";
import { getSource } from "../../../data/money/sources";
var base64data;
const customBase64Uploader = async (event) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

    reader.readAsDataURL(blob);

    reader.onloadend = function () {
        base64data = reader.result;
    };
};

export async function action({ request, params }) {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);
    await createAsset(params.sourceId, data);

    return redirect(`/sources/${params.sourceId}`);
}
export async function loader({ params }) {
    const source = await getSource(params.sourceId);
    if (!source) {
        throw new Response("", {
            status: 404,
            statusText: "Not Found",
        });
    }

    return { source };
}
export default function AddAsset() {
    const { source } = useLoaderData();
    const submit = useSubmit();
    // Formik ---------------------

    const formik = useFormik({
        initialValues: {
            name: '',
            symbol: '',
            isin: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'name is required.';
            }
            if (!data.symbol && source.assetType === "Crypto") {
                errors.symbol = 'symbol is required for crypto asset';
            }
            if (!data.isin && source.assetType === "ETF") {
                errors.isin = 'etf is required for etf asset';
            }



            return errors;
        },
        onSubmit: async (data) => {
            if (base64data)
                data.picture = base64data;
            submit(data, { method: "post" })
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);


    //----------------------------------------


    const items = [
        {
            label: source.name,
            template: () => <a onClick={() => { navigate(-1); }}>{source.name}</a>
        },
        {
            label: "Cr\u00e9ation d'un actif",
        }
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    const navigate = useNavigate();
    return (
        <div>
            <BreadCrumb model={items} home={home} />
        <h1>Cr&eacute;ation d'un actif</h1>
            <Form method="post" id="thumbnail-form" onSubmit={formik.handleSubmit}>
                <Panel header="Actif (PEA, Or, BTC,...)">
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="name" name="name" value={formik.values.name}
                                onChange={(e) => {
                                    formik.setFieldValue('name', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('name') })}
                            />
                            <label htmlFor="name">Nom de l'actif</label>
                        </span>
                        {source.assetType === "Crypto" &&

                            <span className="p-float-label">
                                <InputText id="symbol" name="symbol" value={formik.values.symbol}
                                    onChange={(e) => {
                                        formik.setFieldValue('symbol', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('symbol') })}
                                />
                                <label htmlFor="symbol">Symbole</label>
                            </span>
                        }
                        {source.assetType === "ETF" &&

                            <span className="p-float-label">
                                <InputText id="isin" name="isin" value={formik.values.isin}
                                    onChange={(e) => {
                                        formik.setFieldValue('isin', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('isin') })}
                                />
                                <label htmlFor="isin">ISIN</label>
                            </span>
                        }
                        <span className="p-float-label">
                            <InputText id="link" name="link" value={formik.values.link}
                                onChange={(e) => {
                                    formik.setFieldValue('link', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('link') })}
                            />
                            <label htmlFor="link">Lien de l'actif</label>
                        </span>

                    </div >
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputTextarea id="description" name="description" value={formik.values.description}
                            
                                onChange={(e) => {
                                    formik.setFieldValue('description', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('description') })}
                            />
                            <label htmlFor="description">Commentaire</label>
                        </span>

                    </div >
                    <div className="contentForm"></div >
                </Panel>
                <div className="contentForm"></div >
                <div className="card">
                    <FileUpload name="picture[]" multiple accept="image/*" maxFileSize={1000000} auto uploadHandler={customBase64Uploader} customUpload emptyTemplate={<p className="m-0">D&eacute;posez votre image ici</p>} chooseLabel="Choisir..." />
                </div>

            <p>
                <Button type="submit" size="small">Enregistrer</Button>
                <Button type="button"
                    size="small"
                    severity="secondary"
                    onClick={() => {
                        navigate(-1);
                    }}>Annuler</Button>
                    </p>

            </Form>
            </div>
    );
}