import React, { useState } from "react";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { BreadCrumb } from 'primereact/breadcrumb';
import {
    Form,
    redirect,
    useLoaderData,
    useNavigate,
    useSubmit
} from "react-router-dom";
import { getInvestment, updateInvestment } from "../../../data/money/investments";

export async function action({ request, params }) {

    const formData = await request.formData();
    const data = Object.fromEntries(formData);
    let investmentId = await updateInvestment(data, params.investmentId);

    return redirect(`/sources/${params.sourceId}/investments/${investmentId}`);
}
export async function loader({ params }) {
    const investment = await getInvestment(params.investmentId);
    if (!investment) {
        throw new Response("", {
            status: 404,
            statusText: "Not Found",
        });
    }
    return { investment };
}
export default function EditInvestment() {
    const { investment } = useLoaderData();
    const submit = useSubmit();

    
    const navigate = useNavigate();
    
    const items = [
        {
            label: investment.sourceName,
            template: () => <a onClick={() => {
                navigate(-2);
            }}>{investment.sourceName}</a>
        },
        {
            label: investment.name,
            template: () => <a onClick={() => {
                navigate(-1);
            }}>{investment.name}</a>
        },
        {
            label: "Modification",
        }
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    // Formik ---------------------

    let purchaseDate, nbUnits, value = "";

    const formik = useFormik({
        initialValues: {
            purchaseDate: new Date(investment.purchaseDate),
            nbUnits: investment.nbUnits,
            value: investment.value

        },
        validate: (data) => {
            let errors = {};

            if (!data.purchaseDate) {
                errors.purchaseDate = 'purchaseDate is required.';
            }
            if (!data.nbUnits) {
                errors.nbUnits = 'nbUnits is required.';
            }
            if (!data.value) {
                errors.value = 'value is required.';
            }
            return errors;
        },
        onSubmit: async (data) => {
            submit(data, { method: "post" })
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    //----------------------------------------

    return (
        <div>
        <BreadCrumb model={items} home={home} />
            <Form method="post" id="thumbnail-form" onSubmit={formik.handleSubmit}>

            <h1>{investment.name}</h1>
                <Panel header="Investissement">

                    <div className="contentForm">

                        <span className="p-float-label">
                            <InputText id="nbUnits" name="nbUnits" value={formik.values.nbUnits}
                                onChange={(e) => {
                                    formik.setFieldValue('nbUnits', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('nbUnits') })}
                            />
                            <label htmlFor="nbUnits">Nombre d'unit&eacute;</label>
                        </span>
                        <span className="p-float-label">
                            <InputText id="value" name="value" value={formik.values.value}
                                onChange={(e) => {
                                    formik.setFieldValue('value', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('value') })}
                            />
                            <label htmlFor="value">Prix d'achat en &euro;</label>
                        </span>

                        <span className="p-float-label">
                            <Calendar id="purchaseDate" name="purchaseDate" value={formik.values.purchaseDate}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    formik.setFieldValue('purchaseDate', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('purchaseDate') })}
                            />
                            <label htmlFor="purchaseDate">Date d'achat</label>
                        </span>

                    </div>
                    <div className="contentForm"></div >
                </Panel>
            <p>
                <Button type="submit" size="small">Enregistrer</Button>
                <Button type="button"
                    size="small"
                    severity="secondary"
                    onClick={() => {
                        navigate(-1);
                    }}>Annuler</Button>
            </p>
            </Form>
            </div>
    );
}