import React from "react";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { BreadCrumb } from 'primereact/breadcrumb';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import {
    Form,
    redirect,
    useLoaderData,
    useNavigate,
    useSubmit
} from "react-router-dom";
import { addSource, getSourceTypes, getSource, updateSource } from "../../../data/money/sources";
var base64data;
const customBase64Uploader = async (event) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

    reader.readAsDataURL(blob);

    reader.onloadend = function () {
        base64data = reader.result;
    };
};

export async function loader({ params }) {

    const types = await getSourceTypes();
    if (!types) {
        throw new Response("", {
            status: 404,
            statusText: "Not Found",
        });
    }
    if (params.sourceId) {
        const source = await getSource(params.sourceId);
        if (!source) {
            throw new Response("", {
                status: 404,
                statusText: "Not Found",
            });
        }
        return { types, source };
    }
    const source = null;
    return { types, source };
}


export async function action({ request, params }) {

    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    let id;
    if (params.sourceId)
        id = await updateSource(data, params.sourceId);
    else
        id = await addSource(data);

    return redirect(`/sources/${id}`);
}
export default function AddSource() {
    const navigate = useNavigate();
    const { types, source } = useLoaderData();
    const submit = useSubmit();

    const items = [
        {
            label: "Ajout d'une source d'investissement",
        }
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    // Gestion des types ---------------------

    let formatteTypes = [];
    for (let i = 0; i < types.length; i++) {
        formatteTypes[i] = { ...types[i], name: types[i], value: types[i] };
    }

    const selectedItemTemplate = (item, props) => {
        if (item) {
            return (
                <div className="flex align-items-center">
                    <div>{item.name}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const itemOptionTemplate = (item) => {
        return (
            <div className="flex align-items-center">
                <div>{item.name}</div>
            </div>
        );
    };
    // ----------------------------------------

    let name, type, link, customurl ="";
    if (source) {
        name = source.name;
        type = source.assetType;
        link = source.link;
        customurl = source.customUrl;

    } 
    // Formik ---------------------

    const formik = useFormik({
        initialValues: {
            name: name ?? '',
            type: type ?? '',
            link: link ?? '',
            customurl: customurl ?? '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'name is required.';
            }
            if (!data.type) {
                errors.type = 'type is required.';
            }
            if (!data.link) {   
                errors.link = 'link is required.';
            }
            if (!data.customurl) {
                errors.customurl = 'customurl is required.';
            }


            return errors;
        },
        onSubmit: async (data) => {
            if (base64data)
                data.picture = base64data;
            submit(data, { method: "post" })
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    //----------------------------------------


    return (
        <div>
            <BreadCrumb model={items} home={home} />
            <h1>{(source) ? "Modification" : "Ajout"} d'une source d'investissement</h1>
            <Form method="post" id="thumbnail-form" onSubmit={formik.handleSubmit}>
                <Panel header="Source (Boursorama, Interactive Brokers, Binance, ...)">
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="name" name="name" value={formik.values.name}
                                onChange={(e) => {
                                    formik.setFieldValue('name', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('name') })}
                            />
                            <label htmlFor="name">Nom</label>
                        </span>

                        <Dropdown id="type" name="type" value={formik.values.type}
                            onChange={(e) => {
                                formik.setFieldValue('type', e.target.value);
                            }}
                            className={"w-full md:w-14rem" && classNames({ 'p-invalid': isFormFieldInvalid('type') })}
                            options={formatteTypes} optionLabel="name" placeholder="S&eacute;lectionnez un type"
                            filter valueTemplate={selectedItemTemplate} itemTemplate={itemOptionTemplate} />

                    </div >
                    <div className="contentForm">

                        <span className="p-float-label">
                            <InputText id="link" name="link" value={formik.values.link}
                                onChange={(e) => {
                                    formik.setFieldValue('link', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('link') })}
                            />
                            <label htmlFor="link">Lien</label>
                        </span>

                        <span className="p-float-label">
                            <InputText id="customurl" name="customurl" value={formik.values.customurl}
                                onChange={(e) => {
                                    formik.setFieldValue('customurl', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('customurl') })}
                            />
                            <label htmlFor="customurl">Lien personnalis&eacute;</label>
                            
                        </span>
                   
                       
                        <div className="contentForm"></div >
                    </div>
                    <div className="contentForm"></div >
                </Panel>
                <div className="contentForm"></div >
                <div className="card">
                    <FileUpload name="picture[]" multiple accept="image/*" maxFileSize={1000000} auto uploadHandler={customBase64Uploader} customUpload emptyTemplate={<p className="m-0">D&eacute;posez votre image ici</p>} chooseLabel="Choisir..." />
                </div>

            
            <p>
                <Button type="submit">Enregistrer</Button>
                <Button severity="secondary" type="button"
                    onClick={() => {
                        navigate(-1);
                    }}>Annuler</Button>
            </p>
            </Form>
            </div>
    );
}